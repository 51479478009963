
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$spruha-primary: mat-palette($mat-indigo);
$spruha-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$spruha-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$spruha-theme: mat-light-theme((
  color: (
    primary: $spruha-primary,
    accent: $spruha-accent,
    warn: $spruha-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($spruha-theme);

/* You can add global styles to this file, and also import other style files */

@import "assets/app_styles.scss";
@import "assets/plugins/web-fonts/icons.css";
@import "assets/plugins/web-fonts/font-awesome/font-awesome.min.css";
@import "assets/plugins/web-fonts/plugin.css";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.__instructions{
    height: fit-content;
    margin: auto;
    text-align: center;
    padding: 50px 20px;
    opacity: 0.7;

    max-width: 800px;

    .fa, .zmdi, .mdi{color: var(--dark);font-size: 6em; opacity: 0.4; margin-bottom: 50px;}
    h2{color: var(--primary); font-weight: 300;}
    p{font-size: 1.2em; margin: 20px 0 0;}
}

.mat-snack-bar-container{
  margin: 8px auto !important;
  box-shadow: 0 1px 4px #0006;
  background: var(--primary);
  color: white;
  border-radius: 8px !important;

  background-image: url(./assets/img/trianglePattern.png);
  background-size: cover;
  
  .mat-simple-snackbar-action{
      color: white;
      background: #0002;
      border-radius: 5px;
  }
}

.swal2-container{

  padding: 0 0 0 80px !important;
  bottom: 0 !important;
  left: 0 !important;
  pointer-events: none;
  box-shadow: 0 2px 14px #0006;
  z-index: 10060 !important;

  .swal2-title{display: block !important;}

  .swal2-popup{
    pointer-events: all;
    
    &:not(.swal2-toast){
      background-image: url(./assets/img/modal-texture.png) !important;
      background-size: cover;
      border-radius: 11px !important;
      width: 420px !important;

      .swal2-content{
        font-size: 1rem !important;
      }
    }

    &.swal2-toast{
    margin: 6px auto !important;
    box-shadow: 0 1px 4px #0006;
    color: white;
    border-radius: 8px !important;
    padding: 6px 10px !important;
    width: fit-content !important;
  
    background-image: url(./assets/img/trianglePattern.png) !important;
    background-size: cover !important;
    // font-size: inherit !important;
  }
  }
  .swal2-actions{
    justify-content: space-between;
    padding: 0 !important;
    flex-wrap: nowrap !important;

    .swal2-styled{border-radius: 11px !important; min-width: 33%; margin: 5px auto;}
    .swal2-confirm.swal2-styled{
      background-color: var(--success) !important;
      order: 2;
    }
    .swal2-cancel.swal2-styled{
      background-color: var(--danger) !important;
      order: 1;
    }
  }
}


.__hide-form{
  .form-control{
      padding: 0;
      height: auto;
      border: 0;
      background: none;
  }
  .ng-select .ng-select-container{
      opacity: 1;
      height: auto;
      min-height: fit-content;
      
      .ng-value-container{
          padding: 0;
      }
      .ng-input{
          padding-left: 0;
          padding-right: 0;
      }
      .ng-arrow-wrapper{display: none;}
  }
}

.__band{
  .opacity{opacity: 0.3;}
  &::after{
      content: attr(band-label);
      display: block;
      font-weight: 500;
      width: 130%;
      height: auto;
      padding: 8px 0;
      line-height: 1;
      color: white;
      box-shadow: 1px 3px 8px #000a;
      z-index: 2;
      text-align: center;
      background: linear-gradient(45deg, #a83bd6, #2e00d8);
      position: absolute;
      top: 30px;
      left: -52%;
      transform: rotate(-45deg);
      transform-origin: center;
  }
  &.warning::after{background: linear-gradient(45deg, #d6763b, #da0d73);}
}

.__mention{
  color: #000000cc;
  font-weight: bold;
  position: relative;
  padding: 4px 8px;
  z-index: 1;

  &::before{
    content: "";
    display: inline-block;
    position: relative;
    top: -2px;
    font-size: .9em;
    opacity: .7;
    margin-right: 2px;
  }

  &::after{
    content: "";
    display: block;
    position: absolute;
    top: 0;bottom: 0;
    left: 0;right: 0;
    border-radius: 5px;
    background-color: #00b2ff;
    opacity: 0.4;
    z-index: -1;
  }

  .bubble &{border-radius: 5px;}

  .bubble.self &{--det-color: rgb(83, 201, 248);}

}


.border-radius-0{
  border-radius: 0 !important;

  .ng-select-container{border-radius: 0 !important;}
}

textarea{resize: none !important;}
select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    background-position: right 50%;
    background-repeat: no-repeat;
}